

















































































































































































































































@import "../../../../assets/css/exper/experiDetail";

.detail {
  .tableMember {
    margin-left: 0;
    margin-bottom: 16px;
  }

  .pagination {
    margin-bottom: 16px;
  }
}
